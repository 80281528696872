<template>
  <b-card-code title="吃喝玩乐订单列表">
    <!-- search input -->

    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="搜索"
            type="text"
            class="d-inline-block"
            @input="handleSearch"
          />
        </div>
      </b-form-group>
    </div>
    <!-- table -->
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      :pagination-options="{
        enabled: true,
      }"
      @on-sort-change="onSortChange"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'atwechat'"
          v-b-tooltip.hover
          :title="props.row.wechatid"
          class="text-nowrap"
        >
          {{
            props.row.atwechat == null
              ? props.row.atchatroom
              : props.row.atwechat
          }}
        </span>

        <span v-else-if="props.column.field === 'filmpic'" class="text-nowrap">
          <b-avatar :src="props.row.filmpic" square class="mx-1" />
        </span>
        <!-- Column: Status -->

        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ tkstatus(props.row.status) }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'handlestep'">
          {{ handlestep(props.row.handlestep) }}
        </span>

        <span v-else-if="props.column.field === 'finalprice'">
          {{ props.row.finalprice }}
        </span>
        <!-- Column: Action -->

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <!-- page length -->
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> 显示 </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap"> {{ total }} 条 </span>
          </div>

          <!-- pagination -->
          <div>
            <b-pagination
              :value="1"
              :total-rows="total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  VBTooltip,
  BPopover,
  VBPopover,
  BAvatar,
  BBadge,
  BButton,
  BPagination,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BModal,
  VBModal,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Prism from "vue-prism-component";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCardCode,
    VBPopover,
    VueGoodTable,
    BAvatar,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    vSelect,
    BForm,
    BModal,
    VBModal,
    VBTooltip,
    BPopover,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      log: [],
      pageLength: 10,
      total: 0,
      cid: 0,
      desc: "",
      deviceid: "",
      module1: "",
      selectid: [],
      search: "",
      roleSelect: null,
      roleOptions: [],
      accountSelect: null,
      accountOptions: [],
      accountid: "",
      dir: false,
      pages: ["10", "20", "40", "100"],
      columns: [
        {
          label: "购买用户",
          field: "atwechat",
        },
        {
          label: "订单号",
          field: "orderSn",
        },
        {
          label: "产品名称",
          field: "actName",
        },
        {
          label: "订单名称",
          field: "orderTitle",
        },
        {
          label: "支付金额",
          field: "payPrice",
        },
        {
          label: "佣金",
          field: "jtkShareFee",
        },

        {
          label: "用户佣金",
          field: "yhyj",
        },
        {
          label: "直属佣金",
          field: "zsyj",
        },

        {
          label: "非直属佣金",
          field: "fzsyj",
        },
        {
          label: "平台佣金",
          field: "ptyj",
        },

        {
          label: "付款时间",
          field: "payTime",
        },
        {
          label: "订单状态",
          field: "status",
        },
      ],
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1: "light-success",
        3: "light-primary",
        2: "light-danger",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    tkstatus() {
      const statusColor = {
        /* eslint-disable key-spacing */
        0: "未付款",
        1: "已付款",
        2: "待结算",
        3: "已结算",
        4: "无效订单",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    const user = JSON.parse(localStorage.getItem("userData"));
    this.cid = user.cid;
    useJwt
      .QueryjutuikeOrders({
        cid: this.cid,
        page: 1,
        rows: this.pageLength,
        desc: this.desc,
      })
      .then((res) => {
        // pagelength 一页显示多少条
        console.log("res", res);
        this.total = res.data.data.records;
        this.rows = res.data.data.rows;
      });
  },
  methods: {
    showname(title) {
      var name = "";
      console.log(title.length);
      if (title.length > 10) {
        console.log("title");
        name = title.substring(0, 10) + "...";
      } else {
        name = title;
      }
      return name;
    },
    timestampToTime(cjsj) {
      const date = new Date(cjsj); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      const Y = `${date.getFullYear()}-`;
      const M = `${
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1
      }-`;
      const D = `${
        date.getDate() > 9 ? date.getDate() : "0" + date.getDate()
      } `;
      const h = `${
        date.getHours() > 9 ? date.getHours() : "0" + date.getHours()
      }:`;
      const m = `${
        date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()
      }:`;
      const s =
        date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds();
      return Y + M + D + h + m + s;
    },

    handleSearch(searching) {
      useJwt
        .QueryjutuikeOrders({
          cid: this.cid,
          page: 1,
          rows: this.pageLength,
          wechatnick: searching,
        })
        .then((res) => {
          this.total = res.data.data.records;
          if (this.total == 0) {
            useJwt
              .QueryjutuikeOrders({
                cid: this.cid,
                page: 1,
                rows: this.pageLength,
                wechatid: searching,
              })
              .then((res) => {
                this.total = res.data.data.records;
                if (this.total == 0) {
                  useJwt
                    .QueryjutuikeOrders({
                      cid: this.cid,
                      page: 1,
                      rows: this.pageLength,
                      wechatno: searching,
                    })
                    .then((res) => {
                      this.total = res.data.data.records;
                      if (this.total == 0) {
                        useJwt
                          .QueryjutuikeOrders({
                            cid: this.cid,
                            page: 1,
                            rows: this.pageLength,
                            module: searching,
                          })
                          .then((res) => {
                            this.total = res.data.data.records;
                            this.rows = res.data.data.rows;
                          });
                      }
                      this.rows = res.data.data.rows;
                    });
                }
                this.rows = res.data.data.rows;
              });
          }
          this.rows = res.data.data.rows;
        });

      this.search = searching;
    },
    handleChangePage(page) {
      useJwt
        .QueryjutuikeOrders({
          cid: this.cid,
          page: page,
          rows: this.pageLength,
          desc: this.desc,
          //   wechatid: this.search,
        })
        .then((res) => {
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
    },
    handlePageChange(active) {
      useJwt
        .QueryjutuikeOrders({
          cid: this.cid,
          page: 1,
          rows: active,
          desc: this.desc,
          //   nickname: this.search,
        })
        .then((res) => {
          // pagelength 一页显示多少条
          this.pageLength = active;
          this.rows = res.data.data.rows;
          this.total = res.data.data.records;
        });
    },
    onSortChange(params) {
      this.desc = `${params[0].field}`;
      if (this.desc == "createTime") {
        this.desc = "create_time" + ` ${params[0].type}`;
      } else {
        this.desc = `${params[0].field} ${params[0].type}`;
      }

      useJwt
        .QueryjutuikeOrders({
          cid: this.cid,
          page: 1,
          rows: this.pageLength,
          desc: this.desc,
          //   nickname: this.search,
        })
        .then((res) => {
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
